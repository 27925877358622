import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { logoutResponse } from '../logoutdata/logoutResponse';
import { LogoutserviceService } from '../logoutservice.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  logoutdetails: logoutResponse[];
  spinner: boolean = true;
  table: boolean = false;
  exform: FormGroup;
  constructor(
    private logout: LogoutserviceService,
    private toastr: ToastrService
  ) {
    this.exform = new FormGroup({
      'finalDate': new FormControl()
    })
  }

  ngOnInit() {
    this.getAllDetails();

  }

  displayedColumns: string[] = ['SlNo', 'VisitorImage', 'FullName', 'CompanyName', 'Action'];
  dataSource = new MatTableDataSource([]);
  getAllDetails() {
    this.logout.getDeatils().subscribe((res) => {
      if (!res.error) {
        this.table = true;
        this.spinner = false;
        this.logoutdetails = res.data;
        this.logoutdetails.forEach((ele, i) => {
          ele.SlNo = i + 1
        })
        this.dataSource = new MatTableDataSource([...this.logoutdetails]);
      } else {
        this.toastr.error('Something Went Worng')
      }
    })
  }

  visitorOut(data) {
    let date = new Date();
    let finalDate = date.toLocaleString("en-CA");
    this.exform.get('finalDate').setValue(finalDate);
    this.logout.addLogout(data._id, this.exform.value).subscribe((res) => {
      if (!res.error) {
        // this.getTodayVisitor();
        // this.getVisitor();
        this.getAllDetails()
      }
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        this.toastr.error("CONNECTION_ERROR");
      }
    });
  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

}
