import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { ModalPage } from 'src/app/modal/modal.page';
import { NavController } from '@ionic/angular';
import { ErrorStateMatcher } from '@angular/material/core';
import { RegisterService } from 'src/app/register/register.service';
import { Storage } from '@capacitor/storage';
import { alertController } from '@ionic/core';
@Component({
  selector: 'app-loginpage',
  templateUrl: './loginpage.component.html',
  styleUrls: ['./loginpage.component.scss'],
})

export class LoginpageComponent implements OnInit {
  @ViewChild('triggerForm', {
    static: false
  })
  triggerForm: NgForm;
  exform: FormGroup;
  error: string;
  errorState = false;
  errorMatcher = new CustomErrorStateMatcher();
  submitted = false;

  get addEmployeeFormControl() {
    return this.exform.controls;
  }

  constructor(
    private registerService: RegisterService,
    private toastr: ToastrService,
    private modalCtrl: ModalController,
    private navController: NavController,
    private platform: Platform,

  ) {
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.navController.navigateBack('/home')
    });
  }

  ngOnInit() {
    this.exform = new FormGroup({
      'FullName': new FormControl(null, [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Z][a-zA-Z ]*$/), Validators.maxLength(30)]),
      'CompanyName': new FormControl(null, [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9/._ ]*$/), Validators.maxLength(50)]),
      'finalDate': new FormControl()
    })
  }

  triggerSubmit() {
    if (!this.triggerForm) {
      console.warn('triggerForm not assigned a value');
    } else {
      if (this.triggerForm.valid) {
        this.triggerForm.ngSubmit.emit();
      }
    }
  }
  registerVisitor(exform: FormGroup) {
    let date = new Date();
    let finalDate = date.toLocaleString("en-CA");
    this.exform.get('finalDate').setValue(finalDate);
    this.registerService.registerVisitor(this.exform.value).subscribe(async (res) => {
      if (!res.error) {
        this.submitted = true;
        localStorage.setItem('message', res.message);
        localStorage.setItem('visitorId', res.visitorData[0]['_id'])
        await Storage.set({
          key: "visitorId",
          value: res.visitorData[0]['_id']
        })
        this.registerService.changeVisitorId()
        this.navController.navigateRoot(['register/employeelist'])
      } else {
        this.toastr.error(res.message);
      }
    }, err => {
      if (err.status) {
        this.toastr.error(err.error.message);
      } else {
        if (window.screen.width <= 1024) {
          this.registerService.presentAlert();
        } else {
          this.toastr.error("CONNECTION_ERROR");
        }
      }
    });
  }
  qrCodeOpen() {
    this.modalCtrl.create({
      component: ModalPage,
      backdropDismiss: false,
    }).then(modalres => {
      modalres.present();
    })
  }
}
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: NgForm | FormGroupDirective | null) {
    return control && control.invalid && control.touched;
  }
}
