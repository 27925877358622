import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginpageComponent } from './loginpage/loginpage.component';
import { LogoutComponent } from './logout/logout.component';
import { NetworkErrorComponent } from './network-error/network-error.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [{
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cafeteria',
    loadChildren: () => import('./cafeteria/cafeteria.module').then(m => m.CafeteriaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal',
    loadChildren: () => import('./modal/modal.module').then(m => m.ModalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'camera',
    loadChildren: () => import('./camera/camera.module').then(m => m.CameraModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginpageComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },

  {
    path: 'error',
    component: NetworkErrorComponent
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: "/404"
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
