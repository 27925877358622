import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
  user;

  constructor(
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}
  
  close() {
    this.modalCtrl.dismiss();
  }
}
